import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ManagerService {
  private api = environment.apiUrl;
  constructor(private http: HttpClient) { }

  Upload(data: File, type): Observable<any> {
    const fd = new FormData();
    fd.append('file', data);
    return this.http.post(this.api + 'upload/' + type, fd);
  }

  getDashboard(): Observable<any> {
    return this.http.get(this.api + 'dashboard/');
  }

  getCustomers(): Observable<any> {
    return this.http.get(this.api + 'customer');
  }

  getCustomer(id: number): Observable<any> {
    return this.http.get(this.api + 'customer/' + id);
  }

  getCustomerbyCode(id: string): Observable<any> {
    return this.http.get(this.api + 'customer/code/' + id);
  }

  getCustomerAccount(id: string): Observable<any> {
    return this.http.get(this.api + 'accounts/customer/' + id);
  }

  postCustomer(data): Observable<any> {
    return this.http.post(this.api + 'customer', JSON.stringify(data));
  }

  putCustomer(id: number, data): Observable<any> {
    return this.http.put(this.api + 'customer/' + id, JSON.stringify(data));
  }

  delCustomer(id: number): Observable<any> {
    return this.http.delete(this.api + 'customer/' + id);
  }


  getGroups(): Observable<any> {
    return this.http.get(this.api + 'group');
  }

  getGroup(id: number): Observable<any> {
    return this.http.get(this.api + 'group/' + id);
  }

  getGroupbyCode(id: string): Observable<any> {
    return this.http.get(this.api + 'group/code/' + id);
  }

  getGroupAccount(id: string): Observable<any> {
    return this.http.get(this.api + 'accounts/group/' + id);
  }

  postGroup(data): Observable<any> {
    return this.http.post(this.api + 'group', JSON.stringify(data));
  }

  putGroup(id: number, data): Observable<any> {
    return this.http.put(this.api + 'group/' + id, JSON.stringify(data));
  }

  delGroup(id: number): Observable<any> {
    return this.http.delete(this.api + 'group/' + id);
  }


  getAgents(): Observable<any> {
    return this.http.get(this.api + 'agent');
  }

  getAgent(id: number): Observable<any> {
    return this.http.get(this.api + 'agent/' + id);
  }

  getAgentbyCode(id: string): Observable<any> {
    return this.http.get(this.api + 'agent/code/' + id);
  }

  getAgentAccount(id: string): Observable<any> {
    return this.http.get(this.api + 'accounts/agent/' + id);
  }

  postAgent(data): Observable<any> {
    return this.http.post(this.api + 'agent', JSON.stringify(data));
  }

  putAgent(id: number, data): Observable<any> {
    return this.http.put(this.api + 'agent/' + id, JSON.stringify(data));
  }

  delAgent(id: number): Observable<any> {
    return this.http.delete(this.api + 'agent/' + id);
  }


  getOfficers(): Observable<any> {
    return this.http.get(this.api + 'officer');
  }

  getOfficer(id: number): Observable<any> {
    return this.http.get(this.api + 'officer/' + id);
  }

  getOfficerbyCode(id: string): Observable<any> {
    return this.http.get(this.api + 'officer/code/' + id);
  }

  getOfficerAccount(id: string): Observable<any> {
    return this.http.get(this.api + 'accounts/officer/' + id);
  }

  postOfficer(data): Observable<any> {
    return this.http.post(this.api + 'officer', JSON.stringify(data));
  }

  putOfficer(id: number, data): Observable<any> {
    return this.http.put(this.api + 'officer/' + id, JSON.stringify(data));
  }

  delOfficer(id: number): Observable<any> {
    return this.http.delete(this.api + 'officer/' + id);
  }



  getAccounts(): Observable<any> {
    return this.http.get(this.api + 'accounts');
  }

  getAccountsByStatus(status: string): Observable<any> {
    return this.http.get(this.api + 'accounts/status/' + status);
  }

  getAccount(id: number): Observable<any> {
    return this.http.get(this.api + 'accounts/' + id);
  }

  getAccountbyNumber(no: string): Observable<any> {
    return this.http.get(this.api + 'accounts/number/' + no);
  }

  getAccountTransaction(no: string): Observable<any> {
    return this.http.get(this.api + 'accounts/transaction/' + no);
  }

  getAccountTransact(no: number): Observable<any> {
    return this.http.get(this.api + 'process/transaction/' + no);
  }

  postAccount(data): Observable<any> {
    return this.http.post(this.api + 'accounts', JSON.stringify(data));
  }

  postAccountStatus(data): Observable<any> {
    return this.http.post(this.api + 'process/changestatus/', JSON.stringify(data));
  }

  postAccountCharge(data): Observable<any> {
    return this.http.post(this.api + 'process/charge/', JSON.stringify(data));
  }

  putAccount(id: number, data): Observable<any> {
    return this.http.put(this.api + 'accounts/' + id, JSON.stringify(data));
  }

  delAccount(id: number): Observable<any> {
    return this.http.delete(this.api + 'accounts/' + id);
  }

  Deposit(data): Observable<any> {
    return this.http.post(this.api + 'process/deposit/', JSON.stringify(data));
  }

  Withdrawal(data): Observable<any> {
    return this.http.post(this.api + 'process/withdraw/', JSON.stringify(data));
  }

  postSms(data): Observable<any> {
    return this.http.post(this.api + 'process/sms/', JSON.stringify(data));
  }

  getSessions(): Observable<any> {
    return this.http.get(this.api + 'session');
  }

  getSession(id: number): Observable<any> {
    return this.http.get(this.api + 'session/' + id);
  }

  postSession(data): Observable<any> {
    return this.http.post(this.api + 'session', JSON.stringify(data));
  }

  putSession(id: number, data): Observable<any> {
    return this.http.put(this.api + 'session/' + id, JSON.stringify(data));
  }

  closeSession(id: number, data): Observable<any> {
    return this.http.get(this.api + 'session/close/' + id + '/' + data);
  }

  delSession(id: number): Observable<any> {
    return this.http.delete(this.api + 'session/' + id);
  }

  getAlerts(): Observable<any> {
    return this.http.get(this.api + 'alert');
  }

  getAlert(id: number): Observable<any> {
    return this.http.get(this.api + 'alert/' + id);
  }

  getAlertByType(id: string): Observable<any> {
    return this.http.get(this.api + 'alert/type/' + id);
  }

  postAlert(data): Observable<any> {
    return this.http.post(this.api + 'alert', JSON.stringify(data));
  }

  putAlert(id: number, data): Observable<any> {
    return this.http.put(this.api + 'alert/' + id, JSON.stringify(data));
  }

  delAlert(id: number): Observable<any> {
    return this.http.delete(this.api + 'alert/' + id);
  }

}
