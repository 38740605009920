import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ExportExcelService } from 'src/app/services/export-excel.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';

@Component({
  selector: 'app-relation-officers',
  templateUrl: './relation-officers.component.html',
  styleUrls: ['./relation-officers.component.css']
})
export class RelationOfficersComponent implements OnInit {
  officers: any; filteredOfficer: any[] = []; response: any; search: string = '';
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = value;
    this.getMore(1,10);
    // this.filteredOfficer = this.serachResult(value);
  }
  constructor(private api: ApiService, private use: NavComponent, private _exp: ExportExcelService) { }

  ngOnInit() {
    this.getAllOfficers();
  }

  getAllOfficers() {
    this.api.get('officer').subscribe((data: any) => {
      this.officers = data; this.response = data; 
      this.filteredOfficer = this.officers.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Officer List Fail', 'Unable to Fetch Officer List');
    });
  }

  getMore(page,limit) {
    this.api.get('officer?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.officers = data; this.response = data; 
      this.filteredOfficer = this.officers.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Officer List Fail', 'Unable to Fetch Officer List');
    });
  }
  
  reset(val) {
    val.pin = '1234';
    this.api.put('officer/'+val.officerid, val).subscribe((data: any) => {
      this.use.showMsg('success', 'Pin Reset', 'Officer Pin Reset Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Pin Reset Fail', 'Unable to Reset Officer Pin');
    });
  }

  Export() {
    this.Sort().then((data)=>{
      // return data;
      this._exp.exportAsExcelFile(data, 'group');
    });
    // this._exp.exportAsExcelFile(this.filteredOfficer, 'group');
  }

  async Sort() {
    const officers = [];
    await this.filteredOfficer.forEach((c)=>{
      const grp = { 
        code: c.code, name: c.name, leader: c.group_leader, mobile: c.mobile, secretary: c.group_secretary, secretary_mobile: c.group_secretary_mobile, status: c.active == true? 'Active': 'Disabled', creationTime: c.date 
      };
        officers.push(grp);
    });
    return officers;
  }

  serachResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.officers.filter(a => a.number.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.customer.fullname.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.status.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.grouptype.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.grouptype.basetype.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1);
  }

}
