import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.sass']
})
export class PaginationComponent implements OnInit {
  @Input() response: any; // @Output() changePage: EventEmitter<any>;
  @Input() class: string = 'btn-circle'; 
  @Input() limit: number = 10;
  @Output() changePage = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
  }

  getMore(page) {
    this.changePage.emit(page)
  }
}
