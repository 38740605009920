import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { TellerService } from 'src/app/services/teller.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ApiService } from 'src/app/services/api.service';
import { ManagerService } from 'src/app/services/manager.service';
import { ExportExcelService } from 'src/app/services/export-excel.service';

declare var $: any;

@Component({
  selector: 'app-finance-payment',
  templateUrl: './finance-payment.component.html',
  styleUrls: ['./finance-payment.component.css']
})
export class FinancePaymentComponent implements OnInit {
  payment: any[] = []; filtered: any[] = []; response: any; total = 0; merchants: any[] = []; rpt: any; exp: any[]; id: number;
  type: any[] = [ "ALL","DEPOSIT", "WITHDRAWAL", "PENSION", "LOAN", "SAVINGS"]; 
  view: boolean = false; bank: boolean = false; tran: any; search: string ='';
  // tslint:disable-next-line:variable-name
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = value;
    this.getMore(1,10);
    // this.filtered = this.searchResult(value);
    this.getTotal(this.filtered);
  }
  constructor(private api: ApiService, private set: SetupService, private ext: ExportExcelService, public use: NavComponent) { }

  ngOnInit() {
    $.getScript('assets/js/plugins/footable/footable.all.min.js');
    this.rpt = { merchantid: this.use.active.merchantid, type: '', start: '', end: '', code: 0 }
    this.getAllPayment();
    if(this.use.active.typ=='Admin' || this.use.active.typ=='Support')this.getMerchant();
  }

  getMerchant() {
    this.api.get('Merchant?page=1&limit=100').subscribe((data: any) => {
      this.merchants = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant List');
    });
  }

  getAllPayment() {
    this.api.get('payment').subscribe((data: any) => {
      this.payment = data.data; this.filtered = data.data; this.response = data;
      setTimeout(() => {
        $('.footable').footable();
      }, 1000);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Payment List');
    });
  }
  
  getMore(page,limit) {
    this.api.get('payment?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.payment = data.data; this.filtered = data.data; this.response = data; 
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Transactions List Fail', 'Unable to Fetch Transactions List');
    });
  }

  viewTran(val) {
    this.tran = val;
    this.view = true;
  }

  generate() {
    this.api.post('Payment/Report', this.rpt).subscribe((data: any) => {
      this.payment = data; this.filtered = data; this.getTotal(data);
      setTimeout(() => {
        $('.footable').footable();
      }, 1000);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Payment List');
    });
  }

  postTran(val) {
    this.bank = true;
    this.api.get('Callback/'+ val.code).subscribe((data: any) => {
      val = data; this.bank = true;
      this.getMore(this.response.page, 10);
      this.use.showMsg('success', 'Posting Successful', 'Send to Bank Successful');
    }, (err: HttpErrorResponse) => {
      this.bank = true;
      this.use.showMsg('error', 'Posting Fail', 'Unable to Send Payment to Banking');
    });
  }
  
  postReverse(val) {
    this.bank = true;
    this.api.get('Callback/Reversal/'+ val.code).subscribe((data: any) => {
      val = data; this.bank = true;
      this.getMore(this.response.page, 10);
      this.use.showMsg('success', 'Posting Successful', 'Send to Bank Successful');
    }, (err: HttpErrorResponse) => {
      this.bank = true;
      this.use.showMsg('error', 'Posting Fail', 'Unable to Send Payment to Banking');
    });
  }

  postPayment(val) {
    this.bank = true;
    this.api.get('Callback/Payment/'+ val.code).subscribe((data: any) => {
      val = data; this.bank = true;
      this.getMore(this.response.page, 10);
      this.use.showMsg('success', 'Posting Successful', 'Send to Bank Successful');
    }, (err: HttpErrorResponse) => {
      this.bank = true;
      this.use.showMsg('error', 'Posting Fail', 'Unable to Send Payment to Banking');
    });
  }
  
  putTran(val) {
    // val.code = "PAOC138151";
    // val.tranid = val.code; val.transaction_no = val.code; 
    val.status_code = -1; val.status_message = "FAILED";
    this.api.put('Payment/'+ val.transactionid, val).subscribe((data: any) => {
      val = data;
      this.getMore(this.response.page, 10);
      this.use.showMsg('success', 'Posting Successful', 'Send to Bank Successful');
    }, (err: HttpErrorResponse) => {
      this.bank = true;
      this.use.showMsg('error', 'Posting Fail', 'Unable to Send Payment to Banking');
    });
  }

  getTotal(trans) {
    // console.log(trans);
    this.total = 0;
    trans.forEach((e: any) => {
      if (e.code === 1) {
        this.total += e.amount;
      }
    });
    return this.total;
  }

  export() {
    if(this.rpt.type != null || this.rpt.type != "") {
      this.api.post('Payment/Export', this.rpt).subscribe((data: any) => {
        this.ext.exportAsExcelFile(data, 'payment');
      }, (err: HttpErrorResponse) => {
        this.use.showMsg('error', 'Export Fail', 'Unable to Fetch Payment List');
      });
    } else if (this.filtered.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, 'payment');
      this.use.showMsg('success', 'Transactions Exported', 'Check Downloaded Excel File For Details');
    } else {
      this.use.showMsg('error', 'Transactions Export Fail', 'There is no Transactions Data to Export');
    }
  }

  format() {
    this.id = 1; this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id, CODE: e.tranid, TYPE: e.type, MOBILE: e.mobile, SOURCE: e.source,
        AMOUNT: e.amount, REFERENCE: e.reference, RESPONSE: e.response, DATE: e.date
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

}
