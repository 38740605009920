import { Component, OnInit } from '@angular/core';
import { Router,  ActivatedRoute} from '@angular/router';
import { ManagerService } from 'src/app/services/manager.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ExportExcelService } from 'src/app/services/export-excel.service';
import { ApiService } from 'src/app/services/api.service';


@Component({
  selector: 'app-view-customer',
  templateUrl: './view-customer.component.html',
  styleUrls: ['./view-customer.component.css']
})
export class ViewCustomerComponent implements OnInit {

  cust: any; accnt: any; id: string; rpt: any; statements: any;
  add: boolean; accounts: any[] = []; trans: any[] = []; response: any; search: string = '';
  type: any[] = [ "ALL","DEPOSIT", "WITHDRAWAL", "PENSION", "LOAN", "SAVINGS"];

  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
  }


  constructor(
    private api: ApiService, 
    private _use: NavComponent, 
    private _api: ManagerService, 
    private rout: Router, 
    private router: ActivatedRoute,
    private _exp: ExportExcelService) { }

  ngOnInit() {
    this.id = this.router.snapshot.paramMap.get('id');
    this.getAllCustomers(this.id);
    // this.getAllCustomerAccount(this.id);
    this.rpt = { id: '', type: '', start: '', end: '', code: 0 }
  }


  getAllCustomers(id: any) {
    this.api.get("customer/view/"+id).subscribe((data: any) => {
      this.cust = data; this.accounts = data.accounts;
      this.trans = data.payments;
      
    });
  }

  getAllCustomerAccount(id: any) {
    this._api.getCustomerAccount(id).subscribe((data: any) => {
      this.accnt = data; 
      
    });
  }

  getACcountReport() {
    this.api.post("accounts/statement/",this.rpt).subscribe((data: any) => {
      this.statements = data;
      
    });
  }
  

  // async Sort() {
    
  //   const cust = [];
  //   await this.filteredCust.forEach((c)=>{
  //     const cus = { code: c.code, name: c.fullname, type: c.type, mobile: c.mobile, gender: c.gender, dateofbirth: c.dateofbirth,
  //       locations: c.location.code, maritalstatus: c.maritalstatus, creationTime: c.date };
  //       cust.push(cus);
  //   });
  //   return cust;
  // }


}




