import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-setup-transit',
  templateUrl: './setup-transit.component.html',
  styleUrls: ['./setup-transit.component.css']
})
export class SetupTransitComponent implements OnInit {
  @ViewChild('transitForm') public any: NgForm;
  edit: boolean; list: boolean; trn: any; transit: any[]; res: any; nom: any[]; response: any; search: string = '';
  constructor(private api: ApiService, private _setup: SetupService, private _use: NavComponent) { }

  ngOnInit() {
    this.formReset();
    this.getAllTransit();
  }

  getAllTransit() {
    this._setup.getTransits().subscribe((data: any) => {
      this.transit = data.data; this.response = data;
      this._use.showMsg('success', 'Transit Fetch', 'Fetch Transit list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Transit List'); });
    this._setup.getNominalsByGlType('income').subscribe((data: any) => {
      this.nom = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Nominal List'); });
  }
  
  getMore(page,limit) {
    this.api.get('transit?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.transit = data.data; this.response = data; 
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Transit List Fail', 'Unable to Fetch Transit List');
    });
  }

  editTr(data) {
    // this.api = this.transit.find(s => s.transitid === data);
    this.api = data;
    this.edit = true; this.list = true;
  }
  delTr(dat) {
    this._setup.delTransit(dat.transitid).subscribe((data: any) => {
      this.res = data; this.transit.slice(dat);
    }, (err: HttpErrorResponse) => { alert(err.statusText); });
  }

  postTran() {
    this._setup.getCode('Transit').subscribe((data: any) => {
      this.trn.code = data; // this.addTransit(data);
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Generate New Code for Nominal');
    });
  }

  postTransit() {
    this._setup.postTransit(this.trn).subscribe((data: any) => {
      this.res = data; this.transit.push(data); this.formReset();
      this._use.showMsg('success', 'Transit Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Transit');
    });
  }

  putTransit() {
    this.trn.muserid = this._use.active.Id; this.trn.mdate = new Date();
    this._setup.putTransit(this.trn.transitid, this.api).subscribe((data: any) => {
      this.res = data; this.getAllTransit();
      this.formReset(this.any); this.list = false;
      this._use.showMsg('success', 'Transit Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.trn = {
        transitid: 0, code: null, name: '', method: '', nominalid: '',
        userid: this._use.active.Id, date: new Date(), muserid: null, mdate: null
      };
    }
  }

  listView() {
    this.list = true; this.edit = false;
  }
  back() {
    this.list = false;
  }


}
