import { Routes } from '@angular/router';
import { NavComponent } from './traits/nav/nav.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { FinanceDaybookComponent } from './views/finance/finance-daybook/finance-daybook.component';
import { FinancePaymentComponent } from './views/finance/finance-payment/finance-payment.component';
import { FinanceSummaryComponent } from './views/finance/finance-summary/finance-summary.component';
import { FinanceReportComponent } from './views/finance/finance-report/finance-report.component';
import { SetupUserComponent } from './views/setup/setup-user/setup-user.component';
import { SetupSmslogsComponent } from './views/setup/setup-smslogs/setup-smslogs.component';
import { LoginComponent } from './views/auth/login/login.component';

import { NotfoundComponent } from './views/error/notfound/notfound.component';
import { AuthGuard } from './guards/auth.guard';
import { SetupTransitComponent } from './views/setup/setup-transit/setup-transit.component';
import { ForgetPasswordComponent } from './views/auth/forget-password/forget-password.component';
import { SetupLocationComponent } from './views/setup/setup-location/setup-location.component';
import { ExitpageGuard } from './guards/exitpage.guard';
import { SetupTellerComponent } from './views/setup/setup-teller/setup-teller.component';
import { CustomerComponent } from './views/customer/customer.component';
import { AddCustomerComponent } from './views/customer/add-customer/add-customer.component';

import { AccountGuard } from './guards/account.guard';
import { AdminGuard } from './guards/admin.guard';
import { ManagerGuard } from './guards/manager.guard';

import { TellerPaymentComponent } from './views/teller/teller-payment/teller-payment.component';
import { AlertsComponent } from './views/alerts/alerts.component';
import { AddAccountComponent } from './views/accounts/add-account/add-account.component';
import { AccountsComponent } from './views/accounts/accounts.component';
import { AccountApprovalComponent } from './views/accounts/account-approval/account-approval.component';
import { SetupSessionComponent } from './views/setup/setup-session/setup-session.component';
import { SetupBranchComponent } from './views/setup/setup-branch/setup-branch.component';
import { AccountStatusComponent } from './views/accounts/account-status/account-status.component';

import { MigrationsComponent } from './views/migrations/migrations.component';
import { RelationOfficersComponent } from './views/relation-officers/relation-officers.component';
import { AddRelationOfficerComponent } from './views/relation-officers/add-relation-officer/add-relation-officer.component';
import { ChangeRelationOfficerComponent } from './views/relation-officers/change-relation-officer/change-relation-officer.component';
import { GroupsComponent } from './views/groups/groups.component';
import { AddGroupComponent } from './views/groups/add-group/add-group.component';

import { AgentsComponent } from './views/agents/agents.component';
import { AddAgentComponent } from './views/agents/add-agent/add-agent.component';

import { CustomerReportComponent } from './views/customer/customer-report/customer-report.component';
import { AccountReportComponent } from './views/accounts/account-report/account-report.component';
import { GroupReportComponent } from './views/groups/group-report/group-report.component';
import { ViewGroupComponent } from './views/groups/view-group/view-group.component';
import { SetupCompanyComponent } from './views/setup/setup-company/setup-company.component';
import { ViewAgentComponent } from './views/agents/view-agent/view-agent.component';
import { SetupChargeComponent } from './views/setup/setup-charge/setup-charge.component';
import { ViewCustomerComponent } from './views/customer/view-customer/view-customer.component';

import { ApprovalsComponent } from './views/approvals/approvals.component';
import { ViewRelationOfficerComponent } from './views/relation-officers/view-relation-officer/view-relation-officer.component';
import { SetupCountryComponent } from './views/setup/setup-country/setup-country.component';
import { SetupAccountComponent } from './views/setup/setup-account/setup-account.component';
import { SetupSmsComponent } from './views/setup/setup-sms/setup-sms.component';
import { SetupSequenceComponent } from './views/setup/setup-sequence/setup-sequence.component';
import { LogsComponent } from './views/support/logs/logs.component';


export const appRoute: Routes = [
  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  { path: 'forgot_password', component: ForgetPasswordComponent },
  {
    path: '', canActivate: [AuthGuard], component: NavComponent,
    children: [
      { path: '', component: DashboardComponent, data: { title: 'dashboard' } },
      { path: 'dashboard', component: DashboardComponent, data: { title: 'dashboard' } },
      { path: 'alerts', component: AlertsComponent, data: { title: 'alerts' } },
      {
        path: 'customer', canActivate: [AuthGuard],
        children: [
          { path: '', component: CustomerComponent, data: { title: 'Customer' } },
          { path: 'add', component: AddCustomerComponent, canDeactivate: [ExitpageGuard] },
          { path: 'add/:type', component: AddCustomerComponent, canDeactivate: [ExitpageGuard] },
          { path: 'edit/:id', component: AddCustomerComponent, canDeactivate: [ExitpageGuard] },
          { path: 'edit/:type/:id', component: AddCustomerComponent, canDeactivate: [ExitpageGuard] },
          { path: 'view/:id', component: ViewCustomerComponent }, // canDeactivate: [ExitpageGuard] },
        ]
      },
      {
        path: 'customer-service', canActivate: [AuthGuard],
        children: [
          { path: '', component: CustomerReportComponent, data: { title: 'Customer Report' } },
          { path: 'customer', component: CustomerReportComponent, data: { title: 'Customer Report' } },
          { path: 'group', component: GroupReportComponent },
          { path: 'account', component: AccountReportComponent },
        ]
      },
      {
        path: 'agents', canActivate: [AuthGuard, ManagerGuard],
        children: [
          { path: '', component: AgentsComponent, data: { title: 'Groups' } },
          { path: 'add', component: AddAgentComponent, canDeactivate: [ExitpageGuard] },
          { path: 'edit/:id', component: AddAgentComponent, canDeactivate: [ExitpageGuard], canActivate: [AccountGuard], },
          { path: 'view/:id', component: ViewAgentComponent },
          { path: 'view', component: ViewAgentComponent },
        ]
      },
      {
        path: 'groups', canActivate: [AuthGuard], // canActivate: [ManagerGuard],
        children: [
          { path: '', component: GroupsComponent, data: { title: 'Groups' } },
          { path: 'add', component: AddGroupComponent, canDeactivate: [ExitpageGuard] },
          { path: 'edit/:id', component: AddGroupComponent, canDeactivate: [ExitpageGuard], canActivate: [AccountGuard] },
          { path: 'view/:id', component: ViewGroupComponent },
        ]
      },
      {
        path: 'employee', canActivate: [AccountGuard],
        children: [
          { path: 'users', component: SetupUserComponent, data: { title: 'user' } },
          { path: 'teller', component: SetupTellerComponent, data: { title: 'Teller' } },
        ]
      },
      {
        path: 'officers', canActivate: [AuthGuard], // canActivate: [ManagerGuard],
        children: [
          { path: '', component: RelationOfficersComponent, data: { title: 'Relation Officer' } },
          { path: 'add', component: AddRelationOfficerComponent, canDeactivate: [ExitpageGuard] },
          { path: 'edit/:id', component: AddRelationOfficerComponent, canDeactivate: [ExitpageGuard], canActivate: [AccountGuard] },
          { path: 'view/:id', component: ViewRelationOfficerComponent, data: { title: 'Change' } },
          { path: 'change', component: ChangeRelationOfficerComponent, data: { title: 'Change' }, canActivate: [AccountGuard] },
          // { path: 'teller', component: SetupTellerComponent, data: { title: 'Teller' } },
        ]
      },
      {
        path: 'accounts', canActivate: [AuthGuard],
        children: [
          { path: 'all', component: AccountsComponent },
          { path: 'active', component: AccountsComponent },
          { path: 'status', component: AccountStatusComponent, canActivate: [AccountGuard], },
          { path: 'approval', component: AccountApprovalComponent, canActivate: [AccountGuard], },
          { path: 'add', component: AddAccountComponent, canDeactivate: [ExitpageGuard] },
          { path: 'edit/:id', component: AddAccountComponent, canActivate: [AccountGuard], canDeactivate: [ExitpageGuard] },
          { path: 'setup', component: SetupAccountComponent, canActivate: [AccountGuard] },
        ]
      },
      {
        path: 'teller', canActivate: [AuthGuard],
        children: [
          { path: '', component: TellerPaymentComponent },
          { path: 'payment', component: TellerPaymentComponent },
        ]
      },
      {
        path: 'finance', canActivate: [AuthGuard],
        children: [
          { path: 'approval', component: ApprovalsComponent, canActivate: [ManagerGuard]},
          { path: 'payment', component: FinancePaymentComponent },
          { path: 'daybook', component: FinanceDaybookComponent },
          { path: 'summary', component: FinanceSummaryComponent },
          { path: 'report', component: FinanceReportComponent }
        ]
      },
      { path: 'migrations', component: MigrationsComponent, data: { title: 'Migration Change' }, canActivate: [AdminGuard] },
      {
        path: 'settings', canActivate: [AuthGuard, AccountGuard],
        children: [
          { path: 'charge', component: SetupChargeComponent },
          { path: 'session', component: SetupSessionComponent },
          { path: 'smslog', component: SetupSmslogsComponent },
          { path: 'users', component: SetupUserComponent },
          { path: 'location', component: SetupLocationComponent },
          { path: 'account', component: SetupAccountComponent },
        ]
      },{
        path: 'setup', canActivate: [AuthGuard, AdminGuard],
        children: [
          { path: 'merchant', component: SetupCompanyComponent },
          { path: 'account', component: SetupAccountComponent },
          { path: 'charge', component: SetupChargeComponent },
          { path: 'channel', component: SetupBranchComponent }, 
          { path: 'session', component: SetupSessionComponent },
          { path: 'smslog', component: SetupSmslogsComponent },
          { path: 'users', component: SetupUserComponent },
          { path: 'sequence', component: SetupSequenceComponent },
          { path: 'api', component: SetupSmsComponent },
          { path: 'location', component: SetupLocationComponent },
          { path: 'country', component: SetupCountryComponent }
        ]
      },{
        path: 'support', canActivate: [AuthGuard, AdminGuard],
        children: [
          // { path: 'logs', component: LogsComponent },
        ]
      },
      { path: '404', component: NotfoundComponent, data: { title: 'Error Page' } },
      { path: '**', redirectTo: '404' }
    ]
  },
  { path: '**', redirectTo: '404' }
];
