import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { ExportExcelService } from 'src/app/services/export-excel.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-setup-account',
  templateUrl: './setup-account.component.html',
  styleUrls: ['./setup-account.component.css']
})
export class SetupAccountComponent implements OnInit {
  @ViewChild('accountForm') public any: NgForm;
  accttypes: any[] = []; cots: any[]; nom: any[] = []; seq: any[] = []; 
  response: any; search: string = ''; merchants: any[] = [];
  acct: any; edit: boolean; add: boolean; res: any; filtered: any[];
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = value;
    // this.filtered = this.searchResult(value);
    this.getMore(1,10)
  }
  constructor(private api: ApiService, private _api: SetupService, private use: NavComponent, private _exp: ExportExcelService) { }

  ngOnInit() {
    this.formReset();
    this.getAll();
    this.getAllAccountType();
    if(this.use.active.typ ==="Admin" || this.use.active.typ === "Support") {
      this.getMerchants();
    }
  }

  getAll() {
    // this.api.get('nominal/all').subscribe((data: any) => {
    //   this.nom = data;
    // }, (err: HttpErrorResponse) => {
    //   this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Nominal List');
    // });
    // this.api.get('cot/all').subscribe((data: any) => {
    //   this.cots = data;
    // }, (err: HttpErrorResponse) => {
    //   this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch COT List');
    // });
    this.api.get('sequence?page=1&limit=100').subscribe((data: any) => {
      this.seq = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Sequence List');
    });
  }
  
  getMerchants(page=1,limit=100) {
    this.api.get('merchant?page='+page+'&limit='+limit).subscribe((data: any) => {
      this.merchants = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Employee List'); });
  }

  getAllAccountType() {
    this._api.getAccountTypes().subscribe((data: any) => {
      this.accttypes = data.data; this.response = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch AccountType List');
    });
  }

  getMore(page,limit) {
    this.api.get('accounttype?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.accttypes = data.data; this.response = data; 
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Account List Fail', 'Unable to Fetch Account List');
    });
  }

  Export() {
    this._exp.exportAsExcelFile(this.accttypes, 'accounttypes');
  }

  editType(data) {
    this.acct = data;
    this.edit = true; this.add = true;
  }
  delType(dat) {
    this._api.delAccountType(dat.accountid).subscribe((data: any) => {
      this.res = data; this.accttypes.slice(dat);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Deleting Fail', 'Unable to Delete AccountType');
    });
  }

  postAccountType() {
    this._api.postAccountType(this.acct).subscribe((data: any) => {
      this.res = data; this.getAllAccountType();
      this.formReset(this.any); this.add = false;
      this.use.showMsg('success', 'AccountType Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Adding Fail', 'Unable to Add New AccountType');
    });
  }

  putAccountType() {
    this.acct.muserid = this.use.active.id; this.acct.mdate = new Date();
    this._api.putAccountType(this.acct.accounttypeid, this.acct).subscribe((data: any) => {
      this.res = data; this.getAllAccountType();
      this.formReset(this.any); this.add = false;
      this.use.showMsg('success', 'AccountType Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  searchResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.accttypes.filter(a => a.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.basetype.indexOf(searchString) !== -1);
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.acct = { accounttypeid: 0, basetype: '', limit: 1000, minimum: 5,
        code: '', name: '', sequenceid: null, allow_deposit: true, allow_withdrawal: true, 
        allow_group_withdrawal: false, active: false, amount: 0, loan: 0, days: 0, status: 'Active', 
        merchantid: this.use.active.merchantid,
        userid: this.use.active.id, date: new Date(), muserid: null, mdate: null
      };
    }
  }

  listView() {
    this.add = true; this.edit = false;
  }

  back() {
    this.add = false;
  }

}
