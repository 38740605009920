import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { TellerService } from 'src/app/services/teller.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-finance-daybook',
  templateUrl: './finance-daybook.component.html',
  styleUrls: ['./finance-daybook.component.css']
})
export class FinanceDaybookComponent implements OnInit {
  book: any[]; agents: any[]=[]; groups: any[]=[]; officer: any[]=[]; trans: any[]; 
  types: any[]=["Group","Agent", ];rp: any; cash: any; res: any;
  constructor(private api: ApiService, private _api: TellerService, private _use: NavComponent) { }

  ngOnInit() {
    this.init();
    this.getAll();
  }

  getAll() {
    this.api.get('agent?limit=100').subscribe((data: any) => {
      this.agents = data.data;
    }, (err: HttpErrorResponse) => { });
    this.api.get('group?limit=100').subscribe((data: any) => {
      this.groups = data.data;
    }, (err: HttpErrorResponse) => { });
    this.api.get('officer?limit=100').subscribe((data: any) => {
      this.officer = data.data;
    }, (err: HttpErrorResponse) => { });
  }

  getDayBook() {
    this._api.getDaybook(this.rp).subscribe((data: any) => {
      this.book = data.trans; this.cash = data.cash;
      
    }, (err: HttpErrorResponse) => { });
  }

  init(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.rp = { type: '', id: '', date: null };
      this.cash = { opening: 0, credit: 0, debit: 0, withdrawal: 0, deposit: 0, balance: 0 };
    }
  }

}
