import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { ManagerService } from 'src/app/services/manager.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';

@Component({
  selector: 'app-change-relation-officer',
  templateUrl: './change-relation-officer.component.html',
  styleUrls: ['./change-relation-officer.component.css']
})
export class ChangeRelationOfficerComponent implements OnInit {
  @ViewChild('statusForm') public any: NgForm;
  relationofficers: any[] = []; new: any; set: any; res: any;
  constructor(private api: ApiService, private _use: NavComponent) { }

  ngOnInit() {
    this.formReset();
    this.getAllOfficers();
  }

  getAllOfficers() {
    this.api.get('officer?limit=100').subscribe((data: any) => {
      this.relationofficers = data.data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Officer List Fail', 'Unable to Fetch Officer List');
    });
  }

  postStatus() {
    this.api.post('proccess/changeofficer',this.set).subscribe((data: any) => {
      this.formReset();
      this._use.showMsg('success', 'Status Applied', 'Officer Status Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Update Fail', 'Unable to Change Officer Status');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.set = {
        fromofficerid: null, toofficerid: null, userid: this._use.active.id, date: this._use.date
      };
    }
  }

}
