import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ExportExcelService } from 'src/app/services/export-excel.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-setup-location',
  templateUrl: './setup-location.component.html',
  styleUrls: ['./setup-location.component.css']
})
export class SetupLocationComponent implements OnInit {
  @ViewChild('locationForm') public any: NgForm;
  edit: boolean; list: boolean; loc: any; location: any[]; merchants: any[] = [];
  res: any; response: any; search: string = '';
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = value;
    // this.filtered = this.searchResult(value);
    this.getMore(1,10)
  }
  constructor(private api: ApiService, private _setup: SetupService, private use: NavComponent, private _exp: ExportExcelService) { }

  ngOnInit() {
    this.formReset();
    this.getAllLocation();
    if(this.use.active.typ ==="Admin" || this.use.active.typ === "Support") {
      this.getMerchants();
    }
  }

  getAllLocation() {
    this._setup.getLocations().subscribe((data: any) => {
      this.location = data.data; this.response = data;
      this.use.showMsg('success', 'Location List', 'Fetched Location list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Location List Fail', 'Unable to Fetch Location List'); });
  }

  getMore(page,limit) {
    this.api.get('location?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.location = data.data; this.response = data; 
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Account List Fail', 'Unable to Fetch Account List');
    });
  }
    
  getMerchants(page=1,limit=100) {
    this.api.get('merchant?page='+page+'&limit='+limit).subscribe((data: any) => {
      this.merchants = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Employee List'); });
  }

  Export() {
    this._exp.exportAsExcelFile(this.location, 'locations');
  }

  editLoc(data) {
    // this.loc = this.location.find(s => s.locationid === id).
    this.loc = data;
    this.edit = true;
  }
  delLoc(dat) {
    this._setup.delLocation(dat.locationid).subscribe((data: any) => {
      this.res = data; this.getAllLocation();
    }, (err: HttpErrorResponse) => { alert(err.statusText); });
  }

  postLocation() {
    this._setup.postLocation(this.loc).subscribe((data: any) => {
      this.getAllLocation(); this.formReset(this.any); this.list = false;
      this.use.showMsg('success', 'Location Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Adding Fail', 'Unable to Add New Location');
    });
  }

  putLocation() {
    this.loc.muserid = this.use.active.id; this.loc.mdate = new Date();
    this._setup.putLocation(this.loc.locationid, this.loc).subscribe((data: any) => {
      this.res = data; this.getAllLocation();
      this.formReset(this.any); this.list = false;
      this.use.showMsg('success', 'Location Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.loc = {
        locationid: 0, name: '', code: '', merchantid: this.use.active.merchantid,
        userid: this.use.active.id, date: new Date(), muserid: null, mdate: null
      };
    }
  }
  listView() {
    this.list = true; this.edit = false;
  }
  back() {
    this.list = false;
  }

}
