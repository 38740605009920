import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ManagerService } from 'src/app/services/manager.service';
import { ExportExcelService } from 'src/app/services/export-excel.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.css']
})
export class GroupsComponent implements OnInit {
  groups: any; filteredGroup: any[] = []; response: any; search: string = '';
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = value;
    this.getMore(1,10);
    // this.filteredGroup = this.serachResult(value);
  }
  constructor(private api: ApiService, private use: NavComponent, private _exp: ExportExcelService) { }

  ngOnInit() {
    this.getAllGroups();
  }

  getAllGroups() {
    this.api.get('group').subscribe((data: any) => {
      this.groups = data; this.response = data; 
      this.filteredGroup = this.groups.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Group List Fail', 'Unable to Fetch Group List');
    });
  }

  getMore(page,limit) {
    this.api.get('group?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.groups = data; this.response = data; 
      this.filteredGroup = this.groups.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Group List Fail', 'Unable to Fetch Group List');
    });
  }

  
  reset(val) {
    val.pin = '1234';
    this.api.put('group/'+val.groupid, val).subscribe((data: any) => {
      this.use.showMsg('success', 'Pin Reset', 'Group Pin Reset Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Pin Reset Fail', 'Unable to Reset Group Pin');
    });
  }
  
  Export() {
    this.Sort().then((data)=>{
      // return data;
      this._exp.exportAsExcelFile(data, 'group');
    });
    // this._exp.exportAsExcelFile(this.filteredGroup, 'group');
  }

  async Sort() {
    const groups = [];
    await this.filteredGroup.forEach((c)=>{
      const grp = { 
        code: c.code, name: c.name, leader: c.group_leader, mobile: c.mobile, secretary: c.group_secretary, secretary_mobile: c.group_secretary_mobile, status: c.active == true? 'Active': 'Disabled', creationTime: c.date 
      };
        groups.push(grp);
    });
    return groups;
  }

  serachResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.groups.filter(a => a.number.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.customer.fullname.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.status.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.grouptype.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.grouptype.basetype.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1);
  }

}
