import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-account-cheque',
  templateUrl: './account-cheque.component.html',
  styleUrls: ['./account-cheque.component.css']
})
export class AccountChequeComponent implements OnInit {

  constructor(private api: ApiService) { }

  ngOnInit() {
  }

}
