import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { TellerService } from 'src/app/services/teller.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-setup-teller',
  templateUrl: './setup-teller.component.html',
  styleUrls: ['./setup-teller.component.css']
})
export class SetupTellerComponent implements OnInit {
  @ViewChild('tellerForm') public any: NgForm; res: any;
  edit: boolean; list: boolean; tel: any; tellers: any[]; users: any[]; nom: any[]; response: any; search: string = '';
  constructor(private api: ApiService, private _setup: SetupService, private _api: TellerService, private _use: NavComponent) { }

  ngOnInit() {
    this.formReset();
    this.getAll();
    this.getAllTellers();
  }

  getAll() {
    this.api.get('auth/user?page=1&limit=100').subscribe((data: any) => {
      this.users = data.data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Users Lists', 'Unable to Get All Users');
    });
    this._setup.getNominalsByGlType('Teller').subscribe((data: any) => {
      this.nom = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Nominal Lists', 'Unable to Get All Nominal');
    });
  }

  getAllTellers() {
    this._api.getTellers().subscribe((data: any) => {
      this.tellers = data.data; this.response = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Teller Lists', 'Unable to Get All Tellers');
    });
  }

  getMore(page,limit) {
    this.api.get('teller?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.tellers = data.data; this.response = data; 
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Teller List Fail', 'Unable to Fetch Teller List');
    });
  }

  editTel(dat) {
    // this.tel = this.tellers.find(s => s.tellersid === data);
    this.tel = dat;
    this._api.getTeller(dat.tellerid).subscribe((data: any) => {
      this.tel = data; JSON.stringify(this.tel);
      this.edit = true; this.list = true;
    }, (err: HttpErrorResponse) => { alert(err.error); });
  }
  delTel(dat) {
    this._api.delTeller(dat.tellerid).subscribe((data: any) => {
      this.res = data; this.getAllTellers();
    }, (err: HttpErrorResponse) => { alert(err.error); });
  }

  postTeller() {
    this._api.postTeller(this.tel).subscribe((data: any) => {
      this.res = data; this.formReset(); this.getAllTellers(); 
      this._use.showMsg('success', 'Teller Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Teller');
    });
  }

  putTeller() {
    this.tel.muserid = this._use.active.Id; this.tel.mdate = new Date();
    this._api.putTeller(this.tel.tellerid, this.tel).subscribe((data: any) => {
      this.res = data; this.getAllTellers();
      this.formReset(); this.list = false;
      this._use.showMsg('success', 'Teller Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.tel = { id: '', name: '', nominalid: '', merchantid: this._use.active.merchantid,
        userid: this._use.active.id, date: new Date(), muserid: null, mdate: null
      };
    }
  }
}
